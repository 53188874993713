Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

new Vue({
    el: '#pool',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        view: 'list',

        // search model
        search: {
            expertise_id: 0,
            industry_id: 0,
            location_id: 0,
            keywords: '',
            status: '',
        },

        booking: {
            requirements: ''
        },

        requirements: {
            expertise_id: [],
            industry_id: [],
            location_id: 0,
            requirements: ''
        }
    },

    ready: function() {
        this.getPool();
        this.getExpertiseList();
        this.getIndustryList();
        this.getLocationList();
    },

    methods: {

        listView: function(e) {
            e.preventDefault();
            this.view = 'list';
        },

        tableView: function(e) {
            e.preventDefault();
            this.view = 'table';
        },

        getPool: function() {
            this.$set('loading.pool', true); // show loading spinner

            this.$http.get(this.api_url+'/pool?per_page=100', function(response) {
                this.loading.pool = false; // remove loading spinner
                this.$set('pool', response.pool);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get expertise for select boxes
        getExpertiseList: function() {
            this.$http.get(this.api_url+'/expertise', function(response) {
                var expertiseList = [];
                expertiseList.push({ text: 'All areas of expertise', value: 0 });
                response.expertise.forEach(function(expertise) {
                    expertiseList.push({
                        text: expertise.name,
                        value: expertise.id
                    });
                });
                this.$set('expertiseList', expertiseList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get industries for select boxes
        getIndustryList: function() {
            this.$http.get(this.api_url+'/industries', function(response) {
                var industryList = [];
                industryList.push({ text: 'All industry experience', value: 0 });
                response.industries.forEach(function(industry) {
                    industryList.push({
                        text: industry.name,
                        value: industry.id
                    });
                });
                this.$set('industryList', industryList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get industries for select boxes
        getLocationList: function() {
            this.$http.get(this.api_url+'/locations', function(response) {
                var locationList = [];
                locationList.push({ text: 'All locations', value: 0 });
                response.locations.forEach(function(location) {
                    locationList.push({
                        text: location.name,
                        value: location.id
                    });
                });
                this.$set('locationList', locationList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Search candidates
        searchPool: function(e) {
            e.preventDefault();

            this.loading.pool = true; // show loading spinner

            var url = this.api_url+'/pool?per_page=100';

            if(parseInt(this.search.location_id)) url += '&location_id='+this.search.location_id;
            if(parseInt(this.search.expertise_id)) url += '&expertise_id='+this.search.expertise_id;
            if(parseInt(this.search.industry_id)) url += '&industry_id='+this.search.industry_id;
            if(this.search.keywords) url += '&keywords='+this.search.keywords;
            if(this.search.status) url += '&status='+this.search.status;

            this.$http.get(url, function(response) {
                this.loading.pool = false; // remove loading spinner
                this.pool = response.pool;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Clear search
        clearSearch: function() {
            this.loading.pool = true; // show loading spinner

            this.$http.get(this.api_url+'/pool?per_page=100', function(response) {
                this.loading.pool = false; // remove loading spinner
                this.$set('pool', response.pool);
                this.search = {
                    expertise_id: 0,
                    industry_id: 0,
                    location_id: 0,
                    keywords: '',
                    status: '',
                };
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, request) {
                console.log(request);
            });
        },

        showProfile: function(candidate) {
            this.$set('candidate', candidate);
        },

        showBooking: function(candidate) {
            this.$set('candidate', candidate);
        },

        requestBooking: function(candidate, e) {
            e.preventDefault();

            this.$http.post(this.api_url+'/pool/'+candidate.id+'/book', this.booking, function(response) {

                this.booking = {
                    requirements: ''
                };

                $('#booking').modal('hide');
            });
        },

        registerRequirements: function(e) {
            e.preventDefault();

            this.$http.post(this.api_url+'/pool/requirements', this.requirements, function(response) {

                this.requirements = {
                    expertise_id: 0,
                    industry_id: 0,
                    location_id: 0,
                    requirements: ''
                };

                $('#requirement').modal('hide');
            });
        }
    }
});
