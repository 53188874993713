Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.filter('time', function(minutes) {
    if(! minutes) return '-';

    var hours = Math.floor(minutes / 60);
    var mins = minutes % 60;

    if(mins === 0) return hours+' hr(s)';

    return hours+' hr(s) '+mins+' min(s)';
});
