Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({
    el: '#dashboard',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        notifications: [],
        timesheets: [],
    },

    computed: {
        new: function() {
            return this.notifications.filter(this.filters.unseen);
        }
    },

    ready: function() {
        this.getNotifications();
        this.getTimesheets();
        var self = this;
        window.setInterval(function() {
            self.getNotifications();
        }, 30000); // We'll check for new notifications every 30 seconds
    },

    methods: {
        getNotifications: function() {
            this.$http.get(this.api_url+'/notifications', function(response) {
                this.notifications = response.notifications;
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        seeNotifications: function() {
            this.$http.post(this.api_url+'/notifications', function(response) {
                this.notifications.forEach(function(notification) {
                    notification.seen = true;
                });
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get timesheets
        getTimesheets: function(status) {
            this.$set('loading.timesheets', true); // show loading spinner
            this.$set('status', 'pending');

            this.$http.get(this.api_url+'/timesheets?per_page=10&status='+this.status, function(response) {
                this.loading.timesheets = false; // remove loading spinner
                this.$set('timesheets', response.timesheets);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

    }
});
