Vue.http.headers.common['api-key'] = document.querySelector('meta[name=api_key]').getAttribute('content');

Vue.directive('date', {
    twoWay: true,
    bind: function () {
        var self = this;
        $(self.el).change(function() {
            var value = $(this).val();
            self.set(value);
        });
    },
    unbind: function () {
        var self = this;
    }
});

new Vue({
    el: '#timesheets',

    data: {
        api_url: document.querySelector('meta[name=api_url]').getAttribute('content'),
        view: 'list',

        // search model
        search: {
            from: '',
            to: '',
            candidate_id: 0
        },

        reject: {
            name: '',
            email: '',
            reason: ''
        }
    },

    ready: function() {

        $.fn.datepicker.defaults.autoclose = true;
        $.fn.datepicker.defaults.daysOfWeekDisabled = [1,2,3,4,5,6];
        $.fn.datepicker.defaults.daysOfWeekHighlighted = [0];
        $.fn.datepicker.defaults.startView = 'month';
        $.fn.datepicker.defaults.weekStart = 1;
        this.route();
        var self = this;
        window.onhashchange = function(e) {
            self.route();
        };
    },

    methods: {

        route: function() {

            this.$set('loading.timesheets', true); // show loading spinner

            var hash = (window.location.hash.substr(1)).split('/');

            // index
            if(! hash[0]) {
                this.view = 'list';
                this.getTimesheets();
                this.getCandidateList();
                return;
            }

            // show
            if(hash.length === 1 && ! isNaN(hash[0])) {
                this.view = 'show';
                this.getTimesheet(hash[0]);
                return;
            }

        },

        // Get timesheets
        getTimesheets: function(status) {
            this.$set('loading.timesheets', true); // show loading spinner

            if(status !== 'Active' && status !== 'Pending' && status !== 'Approved' && status !== 'Paid') {
                status = 'Active';
            }

            this.$set('status', status);

            this.$http.get(this.api_url+'/timesheets?status='+this.status, function(response) {
                this.loading.timesheets = false; // remove loading spinner
                this.$set('timesheets', response.timesheets);
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get candidates for select boxes
        getCandidateList: function() {
            this.$http.get(this.api_url+'/candidates', function(response) {
                var candidateList = [];
                candidateList.push({ text: 'All candidates', value: 0 });
                response.candidates.forEach(function(candidate) {
                    candidateList.push({
                        text: candidate.name,
                        value: candidate.id
                    });
                });
                this.$set('candidateList', candidateList);
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Search timesheets
        searchTimesheets: function(e) {
            e.preventDefault();

            this.loading.timesheets = true; // show loading spinner

            var url = this.api_url+'/timesheets?per_page=10';

            url += '?status='+this.status;
            if(this.search.from) url += '&from='+this.search.from;
            if(this.search.to) url += '&to='+this.search.to;
            if(parseInt(this.search.candidate_id)) url += '&candidate_id='+this.search.candidate_id;

            this.$http.get(url, function(response) {
                this.loading.timesheets = false; // remove loading spinner
                this.timesheets = response.timesheets;
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Print timesheets
        printTimesheets: function(e) {
            e.preventDefault();

            this.loading.timesheets = true; // show loading spinner

            var url = this.api_url+'/timesheets';

            url += '?status='+this.status;
            if(this.search.from) url += '&from='+this.search.from;
            if(this.search.to) url += '&to='+this.search.to;
            if(parseInt(this.search.candidate_id)) url += '&candidate_id='+this.search.candidate_id;

            this.$http.get(url, function(response) {
                this.loading.timesheets = false; // remove loading spinner
                this.timesheets = response.timesheets;
                this.view = 'print';
                if(typeof response.pagination !== 'undefined') {
                    this.pagination = response.pagination;
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Show a timesheet's details
        showTimesheet: function(timesheet, e) {
            e.preventDefault();

            this.$set('loading.timesheet', true); // show loading spinner

            this.$http.get(this.api_url+'/timesheets/'+timesheet.id, function(response) {
                this.loading.timesheet = false; // remove loading spinner
                this.$set('timesheet', response.timesheet);
                this.view = 'show';
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        // Get a timesheet
        getTimesheet: function(id) {

            this.$http.get(this.api_url+'/timesheets/'+id, function(response) {
                this.loading.timesheets = false; // remove loading spinner
                this.$set('timesheet', response.timesheet);
                this.view = 'show';
                if(typeof response.pagination !== 'undefined') {
                    this.$set('pagination', response.pagination);
                }
            }).error(function (data, status, response) {
                console.log(response);
            });
        },

        approveTimesheet: function() {
            this.$http.post(this.api_url+'/timesheets/'+this.timesheet.id+'/approve', function(response) {
                this.timesheet.status = 'Approved';
            });
        },

        rejectTimesheet: function() {
            console.log(this.reject);
            this.$http.post(this.api_url+'/timesheets/'+this.timesheet.id+'/reject', this.reject, function(response) {
                this.timesheet.status = 'Rejected';
            });
        },
    },
});
